.bar-chart {
    width: 100% !important;
    max-width: 100%;
}
  
.bar-chart {
    width: 100% !important;
}

@media (max-width: 1024px) {
    .bar-chart {
        max-width: 100%;
    }
}

.bar-chart line.apexcharts-xaxis-tick {
    stroke: transparent;
}

.bar-chart {
    background: transparent
      linear-gradient(62deg, #00369e 0%, #005cfd 53%, #a18dff 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 0px 4px 6px #0000001f;
  
    border-radius: 8px;
}

.bar-chart {
    width: 100% !important;
    max-width: 100%;
}

.linechart h5 {
    font-weight: 700;
    margin: 0px;
}

.linechart {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-card.criclebox {
    box-shadow: 0px 20px 27px #0000000d;
    border-radius: 12px;
}

.ant-card.criclebox .project-ant {
    padding-left: 24px;
    padding-right: 24px;
}
  
.ant-card.criclebox table th {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 14px;
    padding-bottom: 14px;
}

.mb-24 {
    margin-bottom: 24px;
}

.h-full {
    height: 100%;
}

.chart-visitor-count h4 {
    margin: 0px;
    font-weight: 700;
}
  
.chart-visitor-count span {
    color: rgba(0, 0, 0, 0.65);
    font-weight: 600;
}

.ant-typography.lastweek {
    color: #8c8c8c;
    font-weight: 600;
}

.bnb2 {
    color: #52c41a;
    font-weight: 700;
}
.spin-bar-chart {
    min-height: 235px;
    display: flex;
    align-items: center;
    justify-content: center;
}