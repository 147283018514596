#components-layout-demo-responsive .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 251, 250, 0.2);
}
  
.site-layout-sub-header-background {
    background: #fff;
}
  
.site-layout-background {
    background: #fff;
}

.ant-layout-sider-zero-width-trigger {
    right: -40px !important;
    top: 4px !important;
}