.ant-layout {
    background: none !important;
}

@media  screen and ( max-width: 500px ) {
    .max1 {
        width: min('400px', '90%') !important;
    }
    
}

img { max-width: 400px; }

.fixed-bottom {
    position: fixed;
    bottom: 0;
    width: 100%;
}