@font-face {
  font-family: "Futura";
  font-style: "condensed";
  font-weight: "extra bold";
  src: url("../fonts/Futura Condensed Extra Bold.otf") format("truetype");
}


.rdt_Table {
  margin-bottom: 15px;
}
.rdt_TableBody {
  overflow: auto;
  max-height: 75vh;
}

body {
  max-height: 100vh;
  overflow: auto;
}

.ant-list-item-meta-description  {
  font-size: 10px !important;
}

.template-container{
  overflow: auto; 
  width: 80%; 
  margin: 0 auto;
  padding-top: 20;
  display: flex; 
  flex-direction: column;
}