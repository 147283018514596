.ant-card.criclebox {
    box-shadow: 0px 20px 27px #0000000d;
    border-radius: 12px;
    margin-top: 10px;
}

.ant-card.criclebox .project-ant {
    padding-left: 24px;
    padding-right: 24px;
}
  
.ant-card.criclebox table th {
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 14px;
    padding-bottom: 14px;
}
.icon-box {
    width: 48px;
    height: 48px;
    text-align: center;
    background: #1890ff;
    color: #fff;
    border-radius: 0.5rem;
    margin-left: auto;
    line-height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bgred { background: red; }
.bggrey { background: darkgrey; }
.bggreen { background: darkgreen; }

.icon-box svg {
    width: 2em;
    height: 2em;
}

.mb-24 {
    margin-bottom: 24px;
}

.simple-statics-wrapper {
    padding: 0 10px;
}

.spinWrapper {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    background: rgba(0,0,0,0.2);
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}