.dspNone input { display: none; }

.bg_0, .bg_0 input { background-color: rgb(16, 213, 16) !important; border-radius: 0px !important; }
.bg_1, .bg_1 input { background-color: rgb(136, 242, 136) !important;border-radius: 0px !important; }
.bg_2, .bg_2 input { background-color: rgb(245, 237, 122) !important; border-radius: 0px !important; }
.bg_3, .bg_3 input { background-color: rgb(245, 207, 55) !important; border-radius: 0px !important; }
.bg_4, .bg_4 input { background-color: rgb(239, 120, 35) !important; border-radius: 0px !important; }
.bg_5, .bg_5 input { background-color: rgb(247, 4, 4) !important; border-radius: 0px !important; }

/* .ant-row { height: 35px !important; } */

.info-icon {
    display: flex !important;
    align-items: center;
}
.info-icon svg {
    height: 1.5em;
    width: 1.5em;
    margin-left: 5px;
    color: lightblue;
}

.signal {
    display: flex !important;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.signal img {
    height: 125px;
}

.mb5 { margin-bottom:  5px !important;}

.ta .ant-input-number-in-form-item {
    width: 30% !important;
}

.ant-input-number-in-form-item {
    width: 100% !important;
}

.ant-input-number-handler-wrap {
    position: absolute;
    right: 0px;
}

.w100 {
    width: 100%;;
}