.resultsBox {
    padding: 30px;
    border: solid 1px rgb(235, 235, 235);
    border-radius: 30px;
    box-shadow: 5px 5px 5px black;
    margin: 20 20 auto;
    margin-bottom: 30px;
    height: fit-content;
}

.resultsMiniBox {
    padding: 20px 10px;
    border: solid 1px rgb(235, 235, 235);
    border-radius: 10px;
    box-shadow: 5px 5px 5px black;
    margin: 20px 0;
    width: 23.5%;
}
.mr {  margin-right: 2%; }
.ml { margin-left: 5px; }

.flexCol {
    display: flex;
    flex-direction: column;
}

.titleLine {
    font-size: 1.3em;
    font-weight: bold;
    margin: 5px 0;
}

.labelLine {
    font-size: 0.8em;
    margin-bottom: 5px;
    color: rgb(178, 178, 178);
    font-weight: 500;
}

.divider {
    color: #f3f3f3;
    background-color:#f3f3f3;
}

.valueLine {
    font-size: 1.1em;
    font-weight: bold;
    margin-left: 10px;
}

.scoreLine {
    font-size: 8em;
    width: 100%;
    text-align: center;
}

.resultsGlobal {
    display: 'flex';
    flex-direction: 'column';
}

#chart {
    max-width: 90%;
}

.ant-divider-horizontal {
    margin: 10px 0 !important;
}

@media only screen and( max-width: 2799px) {
    .resultsBox {
        min-width: 96% !important;
    }
}

@media only screen and ( min-width: 2800px) {
    .resultsBox {
        max-width: 47% !important;
    }
    .resultsBoxB {
        margin-left: 2%;
    }
}

.df {
    bottom: 0;
    position: absolute;
    justify-content: center;
    text-align: center;
    width: 100%;
}